import React, { useState, useEffect } from "react";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import { useMedia } from "use-media";

import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/people_side.png");

const image1 = require("../../../assets/images/blog_11/b11_1.webp");
const image2 = require("../../../assets/images/blog_11/b11_2.webp");
const image3 = require("../../../assets/images/blog_11/b11_3.jpg");
const image4 = require("../../../assets/images/blog_11/b11_4.jpg");
const image5 = require("../../../assets/images/blog_11/b11_5.jpg");
const image6 = require("../../../assets/images/blog_11/b11_6.jpg");
const image7 = require("../../../assets/images/blog_11/b11_8.webp");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Integrate People HR with chatbot and automate HR tasks | Workativ Blog"
        description="Learn how People HR customers are automating repetitive HR tasks and streamline employee enquiries by building People HR chatbot using workativ assistant."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            People HR Integrations: Automate Business Workflows
                            With Workativ
                          </h1>
                          <img src={image1} className="mb-4"></img>
                        </div>
                      </div>
                      <div className=" market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          According to a recent survey by PWC among 600 HR and
                          HR information technology (IT) leaders on six
                          continents, intelligent automation or process
                          automation is the focus for 45% of the participants.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          With an ever-growing spend on HR technology, HR
                          management seeks out to automate human resources as
                          much as possible, so that the{" "}
                          <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                            human resources staff are freed up
                          </a>{" "}
                          so that they can attend to their jobs with greater
                          efficiency.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          When basic HR tasks like{" "}
                          <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                            onboarding,
                          </a>{" "}
                          <a href="https://workativ.com/use-cases/employee-offboarding-automation">
                            offboarding,&nbsp;
                          </a>
                          etc., are automated, the whole HR department is
                          enabled to shift from a supportive, administrative,
                          and mainly operational department to a front-line,
                          business-driven strategic department that can{" "}
                          <a href="https://workativ.com/conversational-ai-platform/get-over-the-pandemic-with-digital-assistants">
                            provide the agility a business needs to thrive in
                            the future.
                          </a>
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          There are many other benefits as well to businesses
                          automating HR processes. Those include:
                        </p>
                        <ol
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>Going paperless</li>
                          <li>
                            Less time is spent on processes that were once
                            manual
                          </li>
                          <li>Saving on costs, hours, and resources</li>
                          <li>Data is secure in the cloud</li>
                          <li>
                            Cloud-based solutions can scale as requirements
                            change
                          </li>
                        </ol>
                        <p class="font-section-normal-text line-height-2">
                          So the question is, how can your business tap into the
                          power of HR process automation? Why with Workativ, of
                          course!{" "}
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Workativ is a no-code platform for scaling your IT &
                          HR support with AI-based self-service automation.{" "}
                          <a href="https://workativ.com/conversational-ai-platform/reinventing-the-role-of-hr-during-the-covid-19-pandemic-business-continuity-planning-chatbots-and-whatnot">
                            Delivering remote support for employees is more
                            important than ever
                          </a>{" "}
                          and Workativ can help automate employee support with
                          intelligent chatbot and process automation,
                          out-of-the-box. Workativ’s chatbots can be added to
                          any of your instant messaging apps like Slack or
                          Microsoft Teams so that employees can self-serve their
                          IT/HR issues, on-the-go, anytime.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          If your business is using an HR software like People
                          HR, it is high-time you integrated it with Workativ to
                          reap the benefits of automation.
                        </p>

                        <h2 class="font-section-sub-header-small line-height-2">
                          Deliver better HR experience remotely via intelligent
                          HR process automation
                        </h2>
                        <img loading="lazy" src={image2} className="mb-5"></img>
                        <p class="font-section-normal-text line-height-2">
                          Here are some of the People HR tasks that Workativ can
                          take care of for you.
                        </p>

                        <h5 class="font-section-sub-header-small line-height-2">
                          1. Automating the People HR onboarding experience
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          According to the Talent Board North American Candidate
                          Experience Research report, when digital onboarding is
                          used in the hiring stage, new employees are more
                          productive within their first few weeks of work. This
                          sets the stage for the rest of the employees’ tenure
                          at the company and gives them a great head start.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          But digitizing the experience is just the start. You
                          need to automate processes if you want to guarantee a
                          <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                            {" "}
                            smooth onboarding experience.
                          </a>{" "}
                          You can create a process flow on Workativ’s Automation
                          Designer to ensure that important services and assets
                          are delivered in time for the employee’s start date.
                          You can then automate triggers in the process so that
                          once one task is complete, it prompts the next person
                          in the chain to complete their responsibilities.
                        </p>

                        <h5 class="font-section-sub-header-small line-height-2">
                          2. Automating Leave Approvals
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With all the information available to both managers
                          and employees, automated leave management systems are
                          more transparent. They both have access to the leave
                          balance, leave history, and leave application status
                          of an employee. This approval and rejection process
                          has more visibility this way.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          With this information available in the cloud, it makes
                          it easy to access all this information even on mobile
                          devices. By integrating Workativ with your People HR
                          account, you make Workativ your{" "}
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                            one-stop HR support solution
                          </a>{" "}
                          for your firm for leave management.
                        </p>

                        <h5 class="font-section-sub-header-small line-height-2">
                          3. Automating Payroll
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Allowing managers to view employees’ leave history as
                          well as allowing employees to view their salary
                          structure easily without logging in to portals is
                          important for remote work. By connecting Workativ with
                          your People HR account, you enable your managers as
                          well as employees to view employee leaves balances and
                          salary details respectively.
                        </p>
                      </div>

                      <div>
                        <h5 class="font-section-sub-header-small line-height-2">
                          Three ways Workativ helps you with People HR tasks via
                          integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Not only can you automate People HR tasks with
                          Workativ, but you can also combine People HR tasks
                          with other app tasks into one single effective
                          automation. Let’s take a look at some of them.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          1. Effortless Onboarding/Offboarding
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          You can set up an automation with Workativ after
                          connecting your People HR, Office 365, and Slack
                          account such that a new employee gets added to the
                          company’s Office 365 email distribution list after
                          retrieving the employees’ email from People HR and
                          then a message is sent to the company’s Slack channel
                          about the new employee. And when an employee gets
                          terminated, help desk agents can use Workativ’s
                          chatbot to create a ticket on tools like{" "}
                          <a href="https://workativ.com/conversational-ai-platform/jira-chatbot-guide">
                            Jira Service Desk
                          </a>{" "}
                          so that the employee experiences a smooth
                          <a href="https://workativ.com/use-cases/employee-offboarding-automation">
                            &nbsp;offboarding{" "}
                          </a>
                          experience.
                        </p>

                        <img loading="lazy" src={image3} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          2. Seamless Leave Management
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ, leave management becomes simpler with
                          People HR. On their Slack or Microsoft Teams chat, any
                          employee can simply use Workativ on their mobile phone
                          to apply for leaves. A notification is sent
                          immediately to the manager for approval via email and
                          everything is entered in People HR automatically by
                          Workativ. The employee then receives a confirmation on
                          their{" "}
                          <a href="https://workativ.com/conversational-ai-platform/chatbot-channels">
                            Slack or Microsoft Teams chat
                          </a>{" "}
                          as soon as the manager responds to the leave request.
                          Simple, right?
                        </p>
                        <img loading="lazy" src={image4} className="mb-5"></img>

                        <h5 class="font-section-sub-header-small">
                          3. People HR Payroll
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          By integrating Workativ with People HR, you enable
                          employees to{" "}
                          <a href="https://workativ.com/use-cases/payroll-enquiry-automation">
                            view their salary details
                          </a>{" "}
                          with ease. While calculating payroll for employees, HR
                          personnel can also easily view employee details
                          on-demand like the number of leaves taken by the
                          employee, etc., This makes the payroll calculation
                          process much faster and error-free.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          How you can easily set up a People HR Chatbot with
                          Workativ Assistant
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Now that we’ve seen the endless possibilities of what
                          integrating Workativ and People HR and your existing
                          apps can do for you, let’s see how you can go about
                          the same.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          1. Workflow builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Workativ assistant comes with easy-to-use no-code
                          workflow builder to help you build simple to complex
                          app-based automations for chatbot in minutes.
                          Integrate chatbot with apps, use pre-built workflows
                          from marketplace, approve requests, connect chatbot
                          with on-prem apps and much more.
                        </p>

                        <img loading="lazy" src={image5} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          2. Chatbot Builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Our easy-to-use no-code chatbot builder helps you
                          build and manage simple to complex conversations,
                          FAQs, integrate app workflows, and personalize your
                          bot in minutes. Deliver autonomous workplace support
                          with purpose built intelligent chatbots.
                        </p>
                        <img loading="lazy" src={image6} className="mb-3"></img>
                        <img loading="lazy" src={image7} className="mb-5"></img>
                      </div>

                      <NocodeWrapper />
                      <h6 className="font-section-sub-header-small-bold">
                        The Benefits of HR Workflow Automation with Workativ
                        Assistant
                      </h6>
                      <p class="font-section-normal-text line-height-2">
                        Now the benefits are enormous when it comes to
                        <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                          &nbsp;integrating and automating People HR tasks with
                          Workativ.
                        </a>
                      </p>
                      <div className="market_wrapper_page ">
                        <h6 className="font-section-sub-header-small ">
                          1. Information accuracy
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          A major perk of automating leave services is that it
                          gives HR personnel easy access to relevant employee
                          data. From their leave balance to absence trends,
                          everything is available at the click of a button.
                          What’s more is that this data is available in the
                          cloud and can be accessed on the move.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          2. Employee availability
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          One of the biggest advantages an{" "}
                          <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                            automated leave management system{" "}
                          </a>
                          has for managers is the ability to check employee
                          availability. They can check who’s available when and
                          build a work schedule keeping that and project
                          deadlines in mind.
                        </p>
                        <p>
                          This allows them to assign and manage project related
                          tasks as they keep a track of all the work shifts.
                          This way companies can optimize the use of their
                          budgets, workload, and workforce. Having an organized
                          report in front of them will make it easier for them
                          to decide on leave approvals and rejections too.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          3. Implementing discipline
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/use-cases/leave-management-automation">
                            Automated leave tracking
                          </a>{" "}
                          make for a transparent system. One that adheres to
                          company policy and local labor laws. They ensure that
                          employees and managers use the system without any
                          shades of gray.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Employee attendance can also be expected to improve as
                          employees will be aware of the consequences of not
                          adhering to the policy.
                        </p>

                        <p class="font-section-normal-text-medium line-height-2">
                          Liked what you read about Workativ Assistant? Try
                          Workativ Assistant today by signing up for a FREE
                          trial at
                          <a href="https://workativ.com/">
                            &nbsp;workativ.com
                          </a>{" "}
                          and experience the difference yourself.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build
                                Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                                Chatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="https://assistant.workativ.com/authentication/u/direct-signup">
                        <img src={topImage} alt="peoplehr chatbot" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 
            {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free People HR Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="peoplehr chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
